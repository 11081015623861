import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"
import ProjectSection from "../components/projects/builder"
import RelatedProject from "../components/projects/related-project"

export const query = graphql`
  query($slug: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    craft {
      entries(slug: $slug) {
        dateCreated
        dateUpdated
        slug
        url
        uri
        ... on Craft_work_work_Entry {
          seoTitle
          seoDescription
          image: seoImage {
            url(width: 720, height: 475, quality: 100, immediately: true)
          }
          imageFile {
            childImageSharp {
              fixed(width: 600, quality: 90) {
                src
              }
            }
          }
          projectClient
          projectType
          projectLink
          projectContent {
            ... on Craft_projectContent_video_BlockType {
              typeHandle
              mp4 {
                url
              }
              webm {
                url
              }
              ogg {
                url
              }
            }
            ... on Craft_projectContent_projectDetails_BlockType {
              typeHandle
              projectIntroduction
              skills {
                title
              }
            }
            ... on Craft_projectContent_textBlock_BlockType {
              typeHandle
              alignment
              text
            }
            ... on Craft_projectContent_oneColumn_BlockType {
              typeHandle
              image {
                title
                url(width: 1400, quality: 100, immediately: true)
              }
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            ... on Craft_projectContent_twoColumns_BlockType {
              typeHandle
              image {
                title
                url(width: 1400, quality: 100, immediately: true)
              }
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 750, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            ... on Craft_projectContent_threeColumns_BlockType {
              typeHandle
              image {
                title
                url(width: 1400, quality: 100, immediately: true)
              }
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          relatedProjects {
            title
            slug
            ... on Craft_work_work_Entry {
              projectType
              image: projectThumbnail {
                url(width: 800, quality: 100, immediately: true)
              }
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Project = props => {
  const projectData = props.data.craft.entries[0]
  const projectContent = props.data.craft.entries[0].projectContent
  const seoTitle = props.data.craft.entries[0].seoTitle
  const seoDescription = props.data.craft.entries[0].seoDescription
  const seoUrl = props.data.craft.entries[0].slug
  const seoImage =
    props.data.site.siteMetadata.siteUrl +
    props.data.craft.entries[0].imageFile[0].childImageSharp.fixed.src

  const related = projectData.relatedProjects

  const ProjectOverview = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(7, 1fr);
    gap: 2rem;
    padding-top: 5rem;

    h1 {
      font-size: 2.35rem;

      @media (min-width: 46rem) {
        font-size: 3rem;
      }
    }

    @media (min-width: 46rem) {
      padding-top: 12rem;
      padding-bottom: 6rem;
    }
  `

  const OverviewLeft = styled.div`
    grid-column-end: span 7;

    @media (min-width: 46rem) {
      grid-column-end: span 5;

      h1 {
        margin: 0;
      }
    }
  `

  const OverviewRight = styled.div`
    grid-column-end: span 7;

    p {
      font-size: 0.9rem;
      margin: 0;

      &:last-of-type {
        margin-bottom: 2rem;
      }
    }

    @media (min-width: 46rem) {
      grid-column-end: span 2;
      align-self: center;

      p {
        margin: 0 !important;
      }
    }
  `

  const ProjectContent = styled.div`
    section {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`work/${seoUrl}`}
        website
      />
      <ProjectOverview>
        <OverviewLeft>
          <h1>{projectData.projectType}</h1>
        </OverviewLeft>

        <OverviewRight>
          <p>
            <strong>Client: </strong>
            {projectData.projectClient}
          </p>

          {!!projectData.projectLink ? (
            <p>
              <strong>Link: </strong>
              <a
                href={projectData.projectLink}
                title={projectData.projectClient}
              >
                {projectData.projectLink}
              </a>
            </p>
          ) : (
            <></>
          )}
        </OverviewRight>
      </ProjectOverview>

      <ProjectContent>
        {projectContent.map((section, index) => (
          <ProjectSection key={index} section={section} />
        ))}
      </ProjectContent>

      <RelatedProject data={related} />
    </Layout>
  )
}

export default Project
