import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const components = {
  projectDetails: projectDetails,
  video: video,
  oneColumn: oneColumn,
  twoColumns: twoColumns,
  threeColumns: threeColumns,
  textBlock: textBlock,
}

// Project: Overview Block
function projectDetails({ section }) {
  const projectIntroduction = section.projectIntroduction
  const projectSkills = section.skills.map(d => (
    <li key={d.title}>{d.title}</li>
  ))

  const ProjectIntroduction = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;

    @media (min-width: 46rem) {
      margin-top: 9rem;
      margin-bottom: 9rem;
    }
  `

  const IntroText = styled.div`
    grid-column-end: span 10;

    @media (max-width: 46rem) {
      h2 {
        font-size: 1.65rem;
      }
    }

    @media (min-width: 46rem) {
      grid-column-end: span 6;
    }
  `

  const IntroSkills = styled.div`
    grid-column-end: span 10;
    padding-bottom: 2rem;

    @media (min-width: 46rem) {
      grid-column-end: span 2;
      grid-column-start: 8;
      padding: 15rem 0 0;
    }
  `

  const SkillsTitle = styled.h3`
    font-size: 1.2rem;
    margin-bottom: 1rem;
  `

  const SkillsList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;

      &:before {
        content: "—";
        margin-right: 0.55rem;
      }
    }
  `

  return (
    <ProjectIntroduction>
      <IntroText>
        <h2>{projectIntroduction}</h2>
      </IntroText>
      <IntroSkills>
        <SkillsTitle>Services provided:</SkillsTitle>
        <SkillsList>{projectSkills}</SkillsList>
      </IntroSkills>
    </ProjectIntroduction>
  )
}

// Project: Video Block
function video({ section }) {
  const videoSrc = section.mp4[0].url

  const VideoWrapper = styled.section`
    max-width: 98rem;

    @media (min-width: 46rem) {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(1, 1fr);
      gap: 2.5rem;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;

      video {
        width: 100%;
        grid-column-end: span 1;
      }
    }

    @media (max-width: 46rem) {
      display: flex;
      width: 100vw;
      position: relative;
      left: calc(-50vw + 50%);
      overflow: hidden;
      margin-bottom: 2.5rem;

      video {
        width: 140%;
        margin-left: -20%;
      }
    }
  `

  return (
    <VideoWrapper>
      <video muted playsInline loop autoPlay>
        <source src={videoSrc} type="video/mp4"></source>
      </video>
    </VideoWrapper>
  )
}

// Project: One Column/Full Width Image
function oneColumn({ section }) {
  const alt = section.image[0].title
  const fullImage = section.imageFile[0]

  const ProjectSingle = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5rem;
    margin-bottom: 2.5rem;

    @media (min-width: 46rem) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .gatsby-image-wrapper {
      grid-column-end: span 6;

      img {
        background-color: rgb(240, 240, 240);
      }
    }
  `

  return (
    <ProjectSingle>
      <Img fluid={fullImage.childImageSharp.fluid} alt={alt} title={alt} />
    </ProjectSingle>
  )
}

// Project: Two Columns/Half Width Image(s)
function twoColumns({ section }) {
  const leftImage = section.imageFile[0]
  const leftAlt = section.image[0].title
  const rightImage = section.imageFile[1]
  const rightAlt = section.image[1].title

  const ProjectDouble = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5rem;
    margin-bottom: 2.5rem;

    @media (min-width: 46rem) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .gatsby-image-wrapper {
      grid-column-end: span 6;

      img {
        background-color: rgb(240, 240, 240);
      }

      @media (min-width: 46rem) {
        grid-column-end: span 3;
      }
    }
  `

  return (
    <ProjectDouble>
      <Img
        fluid={leftImage.childImageSharp.fluid}
        alt={leftAlt}
        title={leftAlt}
      />

      <Img
        fluid={rightImage.childImageSharp.fluid}
        alt={rightAlt}
        title={rightAlt}
      />
    </ProjectDouble>
  )
}

// Project: Three Columns/Third Width Image(s)
function threeColumns({ section }) {
  const leftImage = section.imageFile[0]
  const leftAlt = section.image[0].title
  const middleImage = section.imageFile[1]
  const middleAlt = section.image[1].title
  const rightImage = section.imageFile[2]
  const rightAlt = section.image[2].title

  const ProjectTriple = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(9, 1fr);
    gap: 2.5rem;
    margin-bottom: 2.5rem;

    @media (min-width: 46rem) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .gatsby-image-wrapper {
      grid-column-end: span 9;

      img {
        background-color: rgb(240, 240, 240);
      }

      @media (min-width: 46rem) {
        grid-column-end: span 3;
      }
    }
  `

  return (
    <ProjectTriple>
      <Img
        fluid={leftImage.childImageSharp.fluid}
        alt={leftAlt}
        title={leftAlt}
      />

      <Img
        fluid={middleImage.childImageSharp.fluid}
        alt={middleAlt}
        title={middleAlt}
      />

      <Img
        fluid={rightImage.childImageSharp.fluid}
        alt={rightAlt}
        title={rightAlt}
      />
    </ProjectTriple>
  )
}

// Project: Text Block
function textBlock({ section }) {
  const align = section.alignment
  const text = section.text

  const ProjectText = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5rem;
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;

    @media (min-width: 46rem) {
      margin-top: 6.5rem;
      margin-bottom: 6.5rem;
    }

    div {
      grid-column-end: span 6;

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @media (min-width: 46rem) {
        grid-column-end: span 3;

        &.left {
          grid-column-start: 1;
        }

        &.right {
          grid-column-start: 4;
        }
      }
    }
  `

  return (
    <ProjectText>
      <div className={`${align}`} dangerouslySetInnerHTML={{ __html: text }} />
    </ProjectText>
  )
}

export default function ProjectSection({ section }) {
  const ComponentName = components[section.typeHandle]

  return <ComponentName section={section} />
}
